import React, {createContext} from "react";

// set the defaults
const QuizContext = createContext({
    quizSuccessful: false,
    setQuizSuccessful: (success: boolean) => {},
    backToMain: false,
    setBackToMain: (success: boolean) => {},
    quizAnswers: [],
    setQuizAnswers: (answerArray: any[]) => {console.log("answers in context set to: ", answerArray)},
    currentQuestionNumber: 0,
    setCurrentQuestionNumber: (currentQuestionNumber: number) => {},
    currentQuestionNumberHealthBenefitsForPatients: 0,
    setCurrentQuestionNumberHealthBenefitsForPatients: (currentQuestionNumber: number) => {},
    currentQuestionNumberDiagnosisOrTherapyOfDiseases: 0,
    setCurrentQuestionNumberDiagnosisOrTherapyOfDiseases: (currentQuestionNumber: number) => {},
    currentQuestionNumberReliefOfDiseases: 0,
    setCurrentQuestionNumberReliefOfDiseases: (currentQuestionNumber: number) => {},
    currentQuestionNumberHealthDamage: 0,
    setCurrentQuestionNumberHealthDamage: (currentQuestionNumber: number) => {},
    additionalQuizQuestions: "no additional questions",
    setAdditionalQuizQuestions: (additionalQuestions: string) => {}
});

export const QuizProvider = QuizContext.Provider

export default QuizContext;
