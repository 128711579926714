import {questions} from "../Questions/Questions";
import React, {useContext, useRef, useState} from "react";
import './AnswerSection.scss';
import {navigate} from "gatsby";
import QuizContext from "../../context/context";

type Props = {
    handleAnswerOptionClick(isCorrect: boolean, question: string, answer: string, additionalQuestions?: string): void;
}

export default function FreeText(props: Props) {

    const [isCorrect, setIsCorrect] = useState<boolean>(true);
    const [questionsArray, setQuestionsArray] = useState<string>("");
    const [answer, setAnswer] = useState<string>("");
    const [characterCount, setCharacterCount] = useState(0);
    const { currentQuestionNumber, setCurrentQuestionNumber } = useContext(QuizContext);

    let currentQuestion = questions[currentQuestionNumber].questionText;

    const handleSubmit = (e) => {
        setAnswer(e);
        setIsCorrect(true);
        setQuestionsArray("no additional questions because end of quiz is reached."); // evtl. einkürzbar, dass hier gleich der Paramter "last question" mitgegeben wird?
        setCharacterCount(answer.length + 1);
    }

    const cancelQuiz = () => {
        navigate('/');
    }

    return (
        <div>
            <>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <textarea
                        className="FreeTextField"
                        value={answer}
                        onChange={e => handleSubmit(e.target.value)}
                        maxLength={200}>
                    </textarea>
                    <span className="CharCount">{characterCount}/200</span>
                </div>

                <div className="QuestionProceedFreeText_Standard">
                    <button className="QuizButtonCancel" onClick={() => cancelQuiz()}>Abbrechen</button>
                    <button className="QuizButtonNextQuestion"
                            onClick={() => {
                                props.handleAnswerOptionClick(isCorrect, currentQuestion, answer, questionsArray);
                            }}>
                        Quiz abschließen
                    </button>
                </div>
            </>
        </div>
    );
}

