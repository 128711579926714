import React, {useContext, useState} from 'react';
import {
    questions,
    questionsHealthBenefitsForPatient,
    questionsHealthDamage,
    questionsReliefOfDiseases,
    questionsDiagnosisOrTherapyOfDiseases
} from "../Questions/Questions";
import './QuestionSection.scss';
import QuizContext from "../../context/context";

type Props = {
    lastQuestion: boolean;
}

export default function QuestionSection(props: Props) {

    const { currentQuestionNumber, setCurrentQuestionNumber } = useContext(QuizContext);
    const { currentQuestionNumberHealthBenefitsForPatients, setCurrentQuestionNumberHealthBenefitsForPatients } = useContext(QuizContext);
    const { currentQuestionNumberDiagnosisOrTherapyOfDiseases, setCurrentQuestionNumberDiagnosisOrTherapyOfDiseases } = useContext(QuizContext);
    const [currentQuestionNumberReliefOfDiseases, setCurrentQuestionNumberReliefOfDiseases] = useState(0);
    const [currentQuestionNumberHealthDamage, setCurrentQuestionNumberHealthDamage] = useState(0);
    const {additionalQuizQuestions, setAdditionalQuizQuestions} = useContext(QuizContext); // wirklich als string oder lieber boolean?

    return (
        <div>
            {props.lastQuestion === false &&
                <>
                    <div>
                        {additionalQuizQuestions === 'healthBenefitsForPatient' &&
                        <div
                            className='QuestionText'>{questionsHealthBenefitsForPatient[currentQuestionNumberHealthBenefitsForPatients].questionText}</div>
                        }
                        {additionalQuizQuestions === 'diagnosisOrTherapyOfDiseases' &&
                        <div
                            className='QuestionText'>{questionsDiagnosisOrTherapyOfDiseases[currentQuestionNumberDiagnosisOrTherapyOfDiseases].questionText}</div>
                        }
                        {additionalQuizQuestions === 'reliefOfDiseases' &&
                        <div
                            className='QuestionText'>{questionsReliefOfDiseases[currentQuestionNumberReliefOfDiseases].questionText}</div>
                        }
                        {additionalQuizQuestions === 'healthDamage' &&
                        <div
                            className='QuestionText'>{questionsHealthDamage[currentQuestionNumberHealthDamage].questionText}</div>
                        }
                        {additionalQuizQuestions === 'no additional questions' &&
                        <div className='QuestionText'>{questions[currentQuestionNumber].questionText}</div>
                        }
                    </div>
                </>
            }
        </div>
    );
}
