import React, {useContext} from "react";
import './AnswerSection.scss';
import StandardQuestions from './StandardQuestions';
import HealthDamageQuestions from "./HealthDamageQuestions";
import HealthBenefitsForPatientsQuestions from "./HealthBenefitsForPatientsQuestions";
import DiagnosisOrTherapyOfDiseasesQuestions from "./DiagnosisOrTherapyOfDiseasesQuestions";
import ReliefOfDiseasesQuestions from "./ReliefOfDiseasesQuestions";
import QuizContext from "../../context/context";

type Props = {
    lastQuestion: boolean;
    handleAnswerOptionClick(isCorrect: boolean, question: string, answer: string, additionalQuestions?: string): void;
    handleAnswerOptionClick_HealthBenefits(isCorrect: boolean, question: string, answer: string, additionalQuestions?: string): void;
    handleAnswerOptionClick_DiagnosisOrTherapyOfDiseases(isCorrect: boolean, question: string, answer: string, additionalQuestions?: string): void;
    handleAnswerOptionClick_ReliefOfDiseases(isCorrect: boolean, question: string, answer: string, additionalQuestions?: string): void;
    handleAnswerOptionClick_HealthDamage(isCorrect: boolean, question: string, answer: string, additionalQuestions?: string): void;
}

export default function AnswerSection(props: Props) {

    const { currentQuestionNumber, setCurrentQuestionNumber } = useContext(QuizContext);
    const {additionalQuizQuestions, setAdditionalQuizQuestions} = useContext(QuizContext); // wirklich als string oder lieber boolean?

    return (
        <div>
            {additionalQuizQuestions === 'healthBenefitsForPatient' &&
            <HealthBenefitsForPatientsQuestions
                handleAnswerOptionClick_HealthBenefits={props.handleAnswerOptionClick_HealthBenefits} />
            }
            {additionalQuizQuestions === 'diagnosisOrTherapyOfDiseases' &&
            <>
                <DiagnosisOrTherapyOfDiseasesQuestions
                    handleAnswerOptionClick_DiagnosisOrTherapyOfDiseases={props.handleAnswerOptionClick_DiagnosisOrTherapyOfDiseases} />
            </>
            }
            {additionalQuizQuestions === 'reliefOfDiseases' &&
            <>
                <ReliefOfDiseasesQuestions
                    handleAnswerOptionClick_ReliefOfDiseases={props.handleAnswerOptionClick_ReliefOfDiseases} />
            </>
            }
            {additionalQuizQuestions === 'healthDamage' &&
            <HealthDamageQuestions handleAnswerOptionClick_HealthDamage={props.handleAnswerOptionClick_HealthDamage} />
            }
            {additionalQuizQuestions === 'no additional questions' &&
            <StandardQuestions handleAnswerOptionClick={props.handleAnswerOptionClick}/>
            }
        </div>
    );
}

