import React, {useState} from 'react';
import {navigate} from "gatsby";
import '../components/Quiz/quiz.scss';
import "./QuizPage.scss";
import Quiz from '../components/Quiz/Quiz';
import QuizContext from "../components/context/context";

export default function QuizPage() {

    const [showQuiz, setShowQuiz] = useState(false);
    const [quizSuccessful, setQuizSuccessful] = useState(false);
    const [backToMain, setBackToMain] = useState(false);
    const [quizAnswers, setQuizAnswers] = useState([]);
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
    const [currentQuestionNumberHealthBenefitsForPatients, setCurrentQuestionNumberHealthBenefitsForPatients] = useState(0);
    const [currentQuestionNumberDiagnosisOrTherapyOfDiseases, setCurrentQuestionNumberDiagnosisOrTherapyOfDiseases] = useState(0);
    const [currentQuestionNumberReliefOfDiseases, setCurrentQuestionNumberReliefOfDiseases] = useState(0);
    const [currentQuestionNumberHealthDamage, setCurrentQuestionNumberHealthDamage] = useState(0);
    const [additionalQuizQuestions, setAdditionalQuizQuestions] = useState("no additional questions");

    const navigateToMainPage = () => {
        navigate('/');
    }

    const handleClick = () => {
        setShowQuiz(true);
    }

    const value = {
        quizSuccessful,
        setQuizSuccessful,
        backToMain,
        setBackToMain,
        quizAnswers,
        setQuizAnswers,
        currentQuestionNumber,
        setCurrentQuestionNumber,
        currentQuestionNumberHealthBenefitsForPatients,
        setCurrentQuestionNumberHealthBenefitsForPatients,
        currentQuestionNumberDiagnosisOrTherapyOfDiseases,
        setCurrentQuestionNumberDiagnosisOrTherapyOfDiseases,
        currentQuestionNumberReliefOfDiseases,
        setCurrentQuestionNumberReliefOfDiseases,
        currentQuestionNumberHealthDamage,
        setCurrentQuestionNumberHealthDamage,
        additionalQuizQuestions,
        setAdditionalQuizQuestions
    };

    //console.log("quiz success? ", value.quizSuccessful);
    //console.log("context? ", value.quizAnswers)
    //console.log("context current question number? ", value.currentQuestionNumber)

    return (
        <QuizContext.Provider value={value}>
            <div className="ContainerQuiz">
                <div className="HeadingQuiz">Wie fit ist Ihr Unternehmen?</div>

                {showQuiz === false &&
                <div className="TextAndButtonQuiz">
                    <div className="TextQuiz">
                        <div>Sie wollen für sich oder Ihr Team abschätzen, welche regulatorischen Aufwände bei
                            der Inverkehrbringung Ihres Medizinproduktes auf Sie zu kommen?</div>
                        <div className="TextQuizContent">
                            <div className="TextStartQuiz">Dann starten Sie jetzt unser Quiz! Die Durchführung nimmt etwa 5 Minuten in Anspruch.</div>
                        </div>
                    </div>
                    <button className="ButtonQuizStart" onClick={handleClick}>Quiz starten</button>
                </div>
                }

                {showQuiz === true && <div className="TextQuiz"><Quiz/></div>}
            </div>
            {quizSuccessful && <div>Test</div>}
        </QuizContext.Provider>
    );
}