import {questions} from "../Questions/Questions";
import React, {useContext, useEffect, useState} from "react";
import './AnswerSection.scss';
import {navigate} from "gatsby";
import QuizContext from "../../context/context";
import RadioButtons from "./RadioButtons";

type Props = {
    //currentQuestion: number;
    handleAnswerOptionClick(isCorrect: boolean, question: string, answer: string, additionalQuestions?: string, exitReason?: string): void;
    //handleBack(question: string, answer: string): void;
}

export default function StandardQuestions(props: Props) {

    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const [questionsArray, setQuestionsArray] = useState<string>("");
    const [answer, setAnswer] = useState<string>("");
    const [disableNextButton, setDisableNextButton] = useState(true);
    const { quizAnswers, setQuizAnswers } = useContext(QuizContext);
    const { currentQuestionNumber, setCurrentQuestionNumber } = useContext(QuizContext);
    const {additionalQuizQuestions, setAdditionalQuizQuestions} = useContext(QuizContext); // wirklich als string oder lieber boolean?
    const [firstQuestion, setFirstQuestion] = useState(false);

    useEffect(() => {
        if (currentQuestion === "Worum handelt es sich bei dem Produkt?") {
            setFirstQuestion(true)
        } else {
            setFirstQuestion(false);
        }
    }, [currentQuestionNumber])


    // aktuelle Frage
    let currentQuestion = "";
    if (currentQuestionNumber >= 0) {
        currentQuestion = questions[currentQuestionNumber].questionText;
    }
    // Antwortoptionen zur aktuellen Frage
    let answersToCurrentQuestion = [];
    if (currentQuestionNumber >= 0) {
        answersToCurrentQuestion = questions[currentQuestionNumber].answerOptions;
    }
    // ExitReasons in den Antwortoptionen zur aktuellen Frage (Array)
    let exitReasonCurrentQuestion = answersToCurrentQuestion.map(a => a.exitReason);
    let exitReason = '';
    exitReasonCurrentQuestion.forEach((exit) => {if(exit) {exitReason = exit}});

    // enable "next" button as soon as an option (radio button) is chosen.
    // when current "next button" is clicked, reset/disable future "next button" again -->
    // prevents that after you chose one time a radio button, you can move through the following ones without choosing one
    const handleDisableButton = (disableButton) => {
        setDisableNextButton(disableButton);
    }

    const cancelQuiz = () => {
        navigate('/');
    }

    const goBackToPreviousQuestion = () => {
        // wenn die letzte Frage NICHT war:
        // ENTWEDER "Dient das Produkt der Diagnose oder Therapie von Krankheiten?"
        // ODER: "Dient das Produkt dem Monitoring von gesundheitsbezogenen Daten und / oder der Prävention von Krankheiten?"
        // ODER: "Dient das Produkt der Linderung von Behinderungen oder Krankheiten oder dem Ersatz von Körperfunktionen"
        // --> setCurrentQuestionNumber(currentQuestionNumber - 1)
        // Denn das bedeutet, wir waren noch nicht bei der Frage mit dem medizinischen Personal --> Zurückzählen von currentQuestionNumber!
        // Wenn wir hingegen bereits beim medizinischen Personal stehen, muss currentQuestionNumber gleich bleiben, da sonst nach dem Zurückbutton und anschließender Antwortauswahl in den StandardQuestions zu weit zurückgesprungen wird

        let diagnosisOrTherapyQuestionFound = quizAnswers.find(entry => (entry.question === "Dient das Produkt der Diagnose oder Therapie von Krankheiten?" && entry.answer === "Ja"));
        let monitoringQuestionFound = quizAnswers.find(entry => entry.question === "Dient das Produkt dem Monitoring von gesundheitsbezogenen Daten und / oder der Prävention von Krankheiten?" && entry.answer === "Ja");
        let reliefQuestionFound = quizAnswers.find(entry => entry.question === "Dient das Produkt der Linderung von Behinderungen oder Krankheiten oder dem Ersatz von Körperfunktionen?");
        let healthDamageFound = quizAnswers.find(entry => entry.question === "Können Patienten durch die Nutzung des Produkts zu Schaden kommen?" && entry.anwer === "Ja");

        let isMedicalPersonalAlreadyAnswered = quizAnswers.find(entry => entry.question === "Inwieweit ist medizinisches Personal in die Nutzung des Produktes involviert?");
        let isMedicalPersonalCurrentQuestion = diagnosisOrTherapyQuestionFound || monitoringQuestionFound || reliefQuestionFound;

        if (!isMedicalPersonalCurrentQuestion || isMedicalPersonalAlreadyAnswered) {
            console.log("wir sind noch nicht beim medizinischen Personal")
            console.log("Medizin answered? ", isMedicalPersonalAlreadyAnswered)
            // entweder wenn wir noch nicht beim medizinischen personal sind
            // ODER wenn wir schon dran vorbei sind ... = wenn in quizAnswers medizinisches Persoal zu finden ist
            setCurrentQuestionNumber(currentQuestionNumber - 1);

            if (healthDamageFound) {
                setAdditionalQuizQuestions("healthDamage")
            }

        } else {
            // Steht "Dient das Produkt der Diagnose oder Therapie von Krankheiten?" bereits in quizAnswers?
            // --> Dann wollen wir nicht in den StandardQuestions zurück, sondern in die "healthBenefitsForPatients" zurückspringen
            if (quizAnswers.find(entry => (entry.question === "Dient das Produkt der Diagnose oder Therapie von Krankheiten?" && entry.answer === "Ja"))) {
                setAdditionalQuizQuestions("healthBenefitsForPatient");
                console.log("set addit quest to health benefis")
            } else if (quizAnswers.find(entry => entry.question === "Dient das Produkt dem Monitoring von gesundheitsbezogenen Daten und / oder der Prävention von Krankheiten?" && entry.answer === "Ja")) {
                setAdditionalQuizQuestions("diagnosisOrTherapyOfDiseases");
            } else if (quizAnswers.find(entry => entry.question === "Dient das Produkt der Linderung von Behinderungen oder Krankheiten oder dem Ersatz von Körperfunktionen")) {
                setAdditionalQuizQuestions("reliefOfDiseases");
            }
            console.log("medizinisches Personal!")
            setCurrentQuestionNumber(currentQuestionNumber);
        }
    }

    return (
        <>
            <RadioButtons 
                question={questions[currentQuestionNumber]} 
                answer={answer} 
                setAnswer={setAnswer} 
                setIsCorrect={setIsCorrect} 
                setQuestionsArray={setQuestionsArray} 
                handleDisableButton={handleDisableButton} 
            />

            <div className="QuestionProceedFreeText_Standard">
                <button className="QuizButtonCancel" onClick={() => cancelQuiz()}>Abbrechen</button>
                {!firstQuestion &&
                <button className="QuizButtonBack" onClick={() => {
                    goBackToPreviousQuestion();
                }}>
                    Zurück
                </button>}
                <button className="QuizButtonNextQuestion"
                        disabled={disableNextButton}
                        onClick={() => {
                            props.handleAnswerOptionClick(isCorrect, currentQuestion, answer, questionsArray, exitReason);
                            handleDisableButton(true);
                            setAnswer("");
                        }}>
                    Nächste Frage
                </button>
            </div>
            </>
    );
}

