import {questions, questionsHealthBenefitsForPatient} from "../Questions/Questions";
import React, {useContext, useEffect, useState} from "react";
import './AnswerSection.scss';
import {navigate} from "gatsby";
import QuizContext from "../../context/context";
import RadioButtons from "./RadioButtons";

type Props = {
    handleAnswerOptionClick_HealthBenefits(isCorrect: boolean, question: string, answer: string, additionalQuestions?: string): void;
}

export default function HealthBenefitsForPatientsQuestions(props: Props) {

    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const [questionsArray, setQuestionsArray] = useState<string>("");
    const [answer, setAnswer] = useState<string>("");
    const [disableNextButton, setDisableNextButton] = useState(true);
    const { currentQuestionNumberHealthBenefitsForPatients, setCurrentQuestionNumberHealthBenefitsForPatients } = useContext(QuizContext);
    const { currentQuestionNumber, setCurrentQuestionNumber } = useContext(QuizContext);
    const [goBack, setGoBack] = useState(false);
    const {additionalQuizQuestions, setAdditionalQuizQuestions} = useContext(QuizContext);

    const handleDisableButton = (disableButton) => {
        setDisableNextButton(disableButton);
    }

    const cancelQuiz = () => {
        navigate('/');
    }

    let currentQuestion = questionsHealthBenefitsForPatient[currentQuestionNumberHealthBenefitsForPatients].questionText;

    const goBackToPreviousQuestion = () => {
        setGoBack(true);
        setCurrentQuestionNumber(currentQuestionNumber - 1)
    }

    useEffect(() => {
        if (goBack) {
            if (currentQuestionNumber > 0) {
                // set to "no additional questions" to render the standard question array again
                setAdditionalQuizQuestions("no additional questions");
            }
        }
    }, [goBack])

    return (
        <div>
            <>
            <RadioButtons 
                question={questionsHealthBenefitsForPatient[currentQuestionNumberHealthBenefitsForPatients]} 
                answer={answer} 
                setAnswer={setAnswer} 
                setIsCorrect={setIsCorrect} 
                setQuestionsArray={setQuestionsArray} 
                handleDisableButton={handleDisableButton} 
            />

            <div className="QuestionProceed">
                <button className="QuizButtonCancel" onClick={() => cancelQuiz()}>Abbrechen</button>
                <button className="QuizButtonBack" onClick={() => {
                    goBackToPreviousQuestion();
                }}>
                    Zurück
                </button>
                <button className="QuizButtonNextQuestion"
                        disabled={disableNextButton}
                        onClick={() => {
                            props.handleAnswerOptionClick_HealthBenefits(isCorrect, currentQuestion, answer, questionsArray);
                        }}>
                    Nächste Frage
                </button>
            </div>
            </>
        </div>
    );
}

