import React, {FC, useContext, useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import './index.css';
import QuestionSection from "./QuestionSection/QuestionSection";
import AnswerSection from "./AnswerSection/AnswerSection";
import {questions} from "./Questions/Questions";
import {questionsHealthBenefitsForPatient} from "./Questions/Questions";
import {questionsDiagnosisOrTherapyOfDiseases} from "./Questions/Questions";
import Exit from "./Exit/Exit";
import FreeText from "./AnswerSection/FreeText";
import Textfield from "./Textfield/Textfield";
import {navigate} from "gatsby";
import QuizContext from "../context/context";

export default function Quiz() {

    const [exit, setExit] = useState(false);
    const [lastQuestion, setLastQuestion] = useState(false);
    const [progressBarFill, setProgressBarFill] = useState((100/9).toString());
    const [freeText, setFreeText] = useState(false);
    const [showProgressBarAndButtons, setShowProgressBarAndButtons] = useState(true);
    const [exitText, setExitText] = useState('');
    const { backToMain, setBackToMain } = useContext(QuizContext);
    const [answerArray, setAnswerArray] = useState([]);
    const { quizAnswers, setQuizAnswers } = useContext(QuizContext);
    const { currentQuestionNumber, setCurrentQuestionNumber } = useContext(QuizContext);
    const [quizSuccessful, setQuizSuccessful] = useState(false);
    const { currentQuestionNumberHealthBenefitsForPatients, setCurrentQuestionNumberHealthBenefitsForPatients } = useContext(QuizContext);
    const { currentQuestionNumberDiagnosisOrTherapyOfDiseases, setCurrentQuestionNumberDiagnosisOrTherapyOfDiseases } = useContext(QuizContext);
    const [currentQuestionNumberReliefOfDiseases, setCurrentQuestionNumberReliefOfDiseases] = useState(0);
    const [currentQuestionNumberHealthDamage, setCurrentQuestionNumberHealthDamage] = useState(0);
    const {additionalQuizQuestions, setAdditionalQuizQuestions} = useContext(QuizContext); // wirklich als string oder lieber boolean?

    const isSmartphone = useMediaQuery({
        query: '(max-device-width: 500px)'
    });

    if (backToMain) {
        navigate('/')
    }

    const value = {
        quizSuccessful,
        setQuizSuccessful,
        backToMain,
        setBackToMain,
        quizAnswers,
        setQuizAnswers,
        currentQuestionNumber,
        setCurrentQuestionNumber,
        currentQuestionNumberHealthBenefitsForPatients,
        setCurrentQuestionNumberHealthBenefitsForPatients,
        currentQuestionNumberDiagnosisOrTherapyOfDiseases,
        setCurrentQuestionNumberDiagnosisOrTherapyOfDiseases,
        currentQuestionNumberReliefOfDiseases,
        setCurrentQuestionNumberReliefOfDiseases,
        currentQuestionNumberHealthDamage,
        setCurrentQuestionNumberHealthDamage,
        additionalQuizQuestions,
        setAdditionalQuizQuestions
    };

    const handleAnswerOptionClick = (isCorrect: boolean, question: string, answer: string, additionalQuestions: string, exitReason?: string) => {
        if (isCorrect) {
            let nextQuestion = currentQuestionNumber + 1;
            let answerArrayCopy = answerArray;

            answerArray.push({
                question: question,
                answer: answer
            });
            setAnswerArray(answerArrayCopy);

            // determine if the same question we wanna paste the answer for was already answered sometime before --> in case back-button was chosen
            if (answerArray.find(entry => entry.question === question)) {
                // ToDo: answerarray.push nur die neue answer zur question
                let newAnswer = { question: question, answer: answer};
                let indexOfOldAnswer = answerArray.findIndex(({ question }) => question === newAnswer.question);
                // Lösche die Stelle, an der der alte Eintrag durch den neuen ersetzt werden soll
                answerArray.splice(indexOfOldAnswer, 1);
                // Füge an die soeben freigewordene Stelle den neuen Eintrag ein
                answerArray[indexOfOldAnswer] = newAnswer;
                //console.log("Überschriebenes Array:  ", answerArray);
                setQuizAnswers([...answerArray]);
            }


            // If previous question was "Kommen die dem Produkt zugrunde liegenden, gesundheitsbezogenen Daten aus dem
            // Labor?", that means it's the last question before the free text field --> set variable that defines
            // rendering of free text field, which structure differs from the answer components before (free text field
            // instead of radio button choice)
            //console.log("my previous question: ", answerArray);
            //console.log("Länge des answerArray: ", answerArray.length);

            if (answerArray.length >= 8 && answerArray.find(item => item.question === "Kommen die dem Produkt zugrunde liegenden, gesundheitsbezogenen Daten aus dem Labor?")) {
                let item = answerArray.find(item => item.question === "Kommen die dem Produkt zugrunde liegenden, gesundheitsbezogenen Daten aus dem Labor?");
                if (item.question === "Kommen die dem Produkt zugrunde liegenden, gesundheitsbezogenen Daten aus dem Labor?") {
                    setFreeText(true);
                    setShowProgressBarAndButtons(false);
                }
            }

            if (additionalQuestions !== "no additional questions") {
                setAdditionalQuizQuestions(additionalQuestions);
            }

            if (nextQuestion < questions.length) {
                setCurrentQuestionNumber(nextQuestion);
                //set progressBar. Es sind 7 Fragen ohne die zusätzlichen Fragen
                setProgressBarFill((((currentQuestionNumber + 2) / 9) * 100).toString());
            } else {
                setLastQuestion(true);
                setFreeText(false);
                console.log("its the last question")
            }

        } else if (!isCorrect) {
            setExitText(exitReason);
            setExit(true);
        }
    };

/*    const handleBack = (question: string, answer: string) => {
        let nextQuestion = currentQuestionNumber - 1;
        console.log("haben wir eine next question? ", nextQuestion);

        if (nextQuestion < 0) {
            console.log("navigating back");
            navigate('/');
        }

        let answerArrayCopy = answerArray;
        answerArray.push({
            question: question,
            answer: answer
        });
        setAnswerArray(answerArrayCopy);

        setCurrentQuestionNumber(nextQuestion);
        //set progressBar. Es sind 7 Fragen ohne die zusätzlichen Fragen
        setProgressBarFill((((currentQuestionNumber - 1) / 7) * 100).toString());
    };*/

    useEffect(() => {
        //console.log("Trigger re-render in Quiz component");
    }, [currentQuestionNumber]);

    const handleAnswerOptionClick_HealthDamage = (isCorrect: boolean, question: string, answer: string, additionalQuestions: string, exitReason?: string) => {

        console.log("handling answer option click damage!");

        if (isCorrect) {
            let nextQuestion = currentQuestionNumberHealthDamage; // VORSICHT doch nur "currentQuestionNumber" ?

            let answerArrayCopy = answerArray;
            answerArray.push({
                question: question,
                answer: answer
            });
            setAnswerArray(answerArrayCopy);
            setQuizAnswers([...answerArray]);

            setAdditionalQuizQuestions('no additional questions');
            if (nextQuestion < questions.length) {
                setCurrentQuestionNumberHealthDamage(nextQuestion); // VORSICHT doch nur "currentQuestionNumber" ?
            } else {
                //do nothing
            }
        } else if (!isCorrect) {
            setExitText(exitReason);
            setExit(true);
        }
    };

    const handleAnswerOptionClick_HealthBenefits = (isCorrect: boolean, question: string, answer: string, additionalQuestions: string) => {
        console.log("handle health benefis answer option click. Addtional questions: ", additionalQuestions)

        if (isCorrect) {
            let nextQuestion = currentQuestionNumberHealthBenefitsForPatients + 1;

            let answerArrayCopy = answerArray;
            answerArray.push({
                question: question,
                answer: answer
            });
            setAnswerArray(answerArrayCopy);
            setQuizAnswers([...answerArray]);

            if (additionalQuestions === 'healthBenefitsForPatient') {
                setAdditionalQuizQuestions('healthBenefitsForPatient');

                if (nextQuestion < questionsHealthBenefitsForPatient.length) {
                    setCurrentQuestionNumberHealthBenefitsForPatients(nextQuestion);
                } else if (nextQuestion === questionsHealthBenefitsForPatient.length) {
                    //setLastQuestion(true);
                    setCurrentQuestionNumberHealthBenefitsForPatients(0);
                    setAdditionalQuizQuestions("no additional questions");
                }
            } else if (additionalQuestions === 'diagnosisOrTherapyOfDiseases') {
                setAdditionalQuizQuestions('diagnosisOrTherapyOfDiseases');
            } else if (additionalQuestions === 'no additional questions') {
                setAdditionalQuizQuestions("no additional questions")
                console.log("current number: ", currentQuestionNumber);
            }
        } else if (!isCorrect) {
            setExit(true);
        }
    };

    const handleAnswerOptionClick_DiagnosisOrTherapyOfDiseases = (isCorrect: boolean, question: string, answer: string, additionalQuestions: string) => {

        console.log("handling answer option click diagnosis!");

        if (isCorrect) {
            let nextQuestion = currentQuestionNumberDiagnosisOrTherapyOfDiseases + 1;

            let answerArrayCopy = answerArray;
            answerArray.push({
                question: question,
                answer: answer
            });
            setAnswerArray(answerArrayCopy);
            setQuizAnswers([...answerArray]);

            if (additionalQuestions === 'diagnosisOrTherapyOfDiseases') { // tritt dieser Fall jemals ein?
                setAdditionalQuizQuestions('diagnosisOrTherapyOfDiseases');

                if (nextQuestion < questionsDiagnosisOrTherapyOfDiseases.length) {
                    setCurrentQuestionNumberDiagnosisOrTherapyOfDiseases(nextQuestion);
                } else if (nextQuestion === questionsDiagnosisOrTherapyOfDiseases.length) {
                    setCurrentQuestionNumberDiagnosisOrTherapyOfDiseases(0);
                    setAdditionalQuizQuestions("no additional questions");
                }
            } else if (additionalQuestions === 'reliefOfDiseases') { // das wäre bei Antwort "nein"
                setAdditionalQuizQuestions('reliefOfDiseases');
            } else if (additionalQuestions === 'no additional questions') {
                setAdditionalQuizQuestions("no additional questions")
            }
        } else if (!isCorrect) {
            setExit(true);
        }
    };

    const handleAnswerOptionClick_ReliefOfDiseases = (isCorrect: boolean, question: string, answer: string, additionalQuestions: string, exitReason?: string) => {

        console.log("handling answer option click relief!");

        if (isCorrect) {
            let nextQuestion = currentQuestionNumberReliefOfDiseases; // VORSICHT doch nur "currentQuestionNumber" ?

            let answerArrayCopy = answerArray;
            answerArray.push({
                question: question,
                answer: answer
            });
            setAnswerArray(answerArrayCopy);
            setQuizAnswers([...answerArray]);

            setAdditionalQuizQuestions('no additional questions');
            if (nextQuestion < questions.length) {
                setCurrentQuestionNumberReliefOfDiseases(nextQuestion); // VORSICHT doch nur "currentQuestionNumber" ?
            }
        } else if (!isCorrect) {
            setExitText(exitReason);
            setExit(true);
        }
    };

    let componentQuiz;

    if (!isSmartphone) {
        componentQuiz =
            <QuizContext.Provider value={value}>
                {exit === true
                    ?
                    <Exit text={exitText}/>
                    :
                    <div>
                        <QuestionSection
                            lastQuestion={lastQuestion}
                        />
                        {freeText === true
                            ?
                            <FreeText handleAnswerOptionClick={handleAnswerOptionClick}/>
                            :
                            <AnswerSection
                                lastQuestion={lastQuestion}

                                handleAnswerOptionClick={handleAnswerOptionClick}
                                handleAnswerOptionClick_HealthBenefits={handleAnswerOptionClick_HealthBenefits}
                                handleAnswerOptionClick_DiagnosisOrTherapyOfDiseases={handleAnswerOptionClick_DiagnosisOrTherapyOfDiseases}
                                handleAnswerOptionClick_ReliefOfDiseases={handleAnswerOptionClick_ReliefOfDiseases}
                                handleAnswerOptionClick_HealthDamage={handleAnswerOptionClick_HealthDamage}
                            />
                        }
                        {lastQuestion === false
                            ?
                            <div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: "40px"
                                }}>
                                    <div className="ProgressBar">
                                        <div className="ProgressBarFill" style={{width: `${progressBarFill}%`}}/>
                                    </div>
                                    <div className="ProgressBarQuestion">Frage {currentQuestionNumber + 1}/9</div>
                                </div>
                            </div>
                            :
                            <Textfield answersQuestionnaire={answerArray}/>
                        }
                    </div>
                }
            </QuizContext.Provider>
    } else {
        componentQuiz =
            <QuizContext.Provider value={value}>
                {exit === true
                    ?
                    <Exit text={exitText}/>
                    :
                    <div>
                        {lastQuestion === false
                            ?
                            <div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}>
                                    <div className="ProgressBar">
                                        <div className="ProgressBarFill" style={{width: `${progressBarFill}%`}}/>
                                    </div>
                                    <div className="ProgressBarQuestion">Frage {currentQuestionNumber + 1}/9</div>
                                </div>
                            </div>
                            :
                            <Textfield answersQuestionnaire={answerArray}/>
                        }
                        <QuestionSection
                            lastQuestion={lastQuestion}
                        />
                        {freeText === true
                            ?
                            <FreeText
                                      handleAnswerOptionClick={handleAnswerOptionClick}/>
                            :
                            <AnswerSection
                                lastQuestion={lastQuestion}

                                handleAnswerOptionClick={handleAnswerOptionClick}
                                handleAnswerOptionClick_HealthBenefits={handleAnswerOptionClick_HealthBenefits}
                                handleAnswerOptionClick_DiagnosisOrTherapyOfDiseases={handleAnswerOptionClick_DiagnosisOrTherapyOfDiseases}
                                handleAnswerOptionClick_ReliefOfDiseases={handleAnswerOptionClick_ReliefOfDiseases}
                                handleAnswerOptionClick_HealthDamage={handleAnswerOptionClick_HealthDamage}
                                //handleBack={handleBack}
                            />
                        }
                    </div>
                }
            </QuizContext.Provider>
    }

    return (
        <div className='app'>
            {componentQuiz}
        </div>
    )
}
