export const questions = [
    {
        questionText: 'Worum handelt es sich bei dem Produkt?',
        answerOptions: [
            { answerText: 'Software', isCorrect: true, questionArray: 'no additional questions' },
            { answerText: 'Hardware', isCorrect: true, questionArray: 'no additional questions'},
            { answerText: 'Eine Kombination aus Software und Hardware', isCorrect: true, questionArray: 'no additional questions'},
        ],
    },
    {
        questionText: 'Ist das Produkt ein Zubehör zu einem bereits auf dem Markt befindlichen Medizinprodukt?',
        answerOptions: [
            { answerText: 'Ja', isCorrect: false, questionArray: 'no additional questions', exitReason: 'Vermutlich ist Ihr Produkt ' + 
                        'ein Medizinprodukt mindestens derselben Risikoklasse wie das Hauptprodukt. Damit wir Sie kompetent beraten können, ' + 
                        'ist ein weiterführendes Gespräch nötig. \nGerne können Sie uns direkt kontaktieren.'},
            { answerText: 'Nein', isCorrect: true, questionArray: 'no additional questions'},
        ],
    },
    {
        questionText: 'Welche Märkte werden mit dem Produkt anvisiert?',
        answerOptions: [
            { answerText: 'Europäische Union (EU)', isCorrect: true, questionArray: 'no additional questions' },
            { answerText: 'USA', isCorrect: true, questionArray: 'no additional questions' },
            { answerText: 'andere Länder', isCorrect: false, questionArray: 'no additional questions', exitReason: 'Der' +
                    'zeit unterstützen wir nur Zulassungen in der EU und beraten für den Einsatz in USA.' + 
                    '\nDamit wir Sie kompetent beraten können, ist ein weiterführendes Gespräch nötig. Gerne können Sie uns direkt kontaktieren.' }, // exit
        ],
    },
    {
        questionText: 'Ist das Produkt für Menschen bestimmt?',
        answerOptions: [
            { answerText: 'Ja', isCorrect: true, questionArray: 'no additional questions', notice: 'Beispiele: Monitoring von Vitalparametern zur Überwachung chronischer Krankheiten, Software für medizinisches Personal zur elektronischen Rezepterstellung mit Überprüfung von Medikamenten-Wechselwirkungen' },
            { answerText: 'Nein', isCorrect: true, questionArray: 'no additional questions', notice: 'Beispiel: Service für eine verbesserte Administration in der Klinik' },
        ],
    },
    {
        questionText: 'Hat das Produkt einen gesundheitlichen Nutzen für Patienten?',
        answerOptions: [
            { answerText: 'Ja, hat es.', isCorrect: true, questionArray: 'healthBenefitsForPatient', questnum: '3', notice: 'Beispiel: Die Software verkürzt die durchschnittliche Verweildauer von Patienten durch verbesserte administrative Prozesse in der Klinik.'},
            { answerText: 'Nein, hat es nicht.', isCorrect: false, questionArray: 'no additional questions', exitReason: 'Laut MDR ist ein Medizinprodukt ein Produkt, ' + 
                            'welches einen gesundheitlichen Nutzen für Patienten bietet. Damit wir Sie kompetent beraten können, ist ein weiterführendes Gespräch nötig. ' + 
                            '\nGerne können Sie uns direkt kontaktieren.', notice: 'Beispiel: Die Software optimiert administrative Prozesse in der Klinik.'},
        ],
    },
    {
        questionText: 'Inwieweit ist medizinisches Personal in die Nutzung des Produktes involviert?',
        answerOptions: [
            { answerText: 'Das medizinische Personal trifft die letztendliche Entscheidung ', 
              notice: 'Hinweis: Entscheidungsvorschläge vom Produkt können berücksichtigt werden oder nicht', isCorrect: true, questionArray: 'no additional questions' },
            { answerText: 'Das medizinische Personal trifft nicht die letztendliche Entscheidung, sondern das Produkt.', isCorrect: true, questionArray: 'no additional questions' },
        ],
    },
    {
        questionText: 'Können Patienten durch die Nutzung des Produkts zu Schaden kommen?',
        answerOptions: [
            { answerText: 'Ja', isCorrect: true, questionArray: 'healthDamage' },
            { answerText: 'Nein', isCorrect: true, questionArray: 'no additional questions' },
        ],
    },
    {
        questionText: 'Kommen die dem Produkt zugrunde liegenden, gesundheitsbezogenen Daten aus dem Labor?',
        answerOptions: [
            { answerText: 'Ja', isCorrect: true, questionArray: 'no additional questions', notice: 'Beispiele: Mikrobiologische Labordaten, Blutbilder, Gensequenzen' },
            { answerText: 'Nein', isCorrect: true, questionArray: 'no additional questions' },
        ],
    },
    {
        questionText: 'Haben Sie noch weitere, wichtige Anmerkungen?',
        answerOptions: [
            { answerText: 'Ich bin ein Freitextfeld', isCorrect: true, questionArray: 'free text' },
        ],
    },

];

export const questionsHealthBenefitsForPatient = [
    {
        questionText: 'Dient das Produkt der Diagnose oder Therapie von Krankheiten?',
        answerOptions: [
            { answerText: 'Ja', isCorrect: true, questionArray: 'no additional questions' },
            { answerText: 'Nein', isCorrect: true, questionArray: 'diagnosisOrTherapyOfDiseases' },
        ],
    },
];

export const questionsDiagnosisOrTherapyOfDiseases = [
    {
        questionText: 'Dient das Produkt dem Monitoring von gesundheitsbezogenen Daten und / oder der Prävention von Krankheiten?',
        answerOptions: [
            { answerText: 'Ja', isCorrect: true, questionArray: 'no additional questions' },
            { answerText: 'Nein', isCorrect: true, questionArray: 'reliefOfDiseases' },
        ],
    },
];

export const questionsReliefOfDiseases = [
    {
        questionText: 'Dient das Produkt der Linderung von Behinderungen oder Krankheiten oder dem Ersatz von Körperfunktionen?',
        answerOptions: [
            { answerText: 'Ja', isCorrect: true, questionArray: 'no additional questions', notice: 'Beispiele: Gehhilfen, elektronische Schmerzlinderung, heilende Wärme, Prothesen' },
            { answerText: 'Nein', isCorrect: false, questionArray: 'no additional questions', exitReason: 'Die Einstufung Ihres Medizinproduktes gemäß MDR ist unklar. ' + 
            'Damit wir Sie kompetent beraten können, ist ein weiterführendes Gespräch nötig. \nGerne können Sie uns direkt kontaktieren.'}, // exit
        ],
    },
];

export const questionsHealthDamage = [
    {
        questionText: 'Welche gesundheitlichen Schäden können Patienten bei bestimmungsgemäßer Nutzung des Produktes im schlimmsten Fall erleiden? ' +
            'Bitte kreuzen Sie die zutreffendste Antwort an',
        answerOptions: [
            { answerText: 'keine Schäden', isCorrect: false, questionArray: 'no additional questions', exitReason: 'Da Medizinprodukte durch ihre ' +
                    'Wirkung auf die Gesundheit des Menschen definiert sind, bedeutet eine falsche Nutzung immer Schaden für den Patienten. ' +
                    'Vermutlich handelt es sich bei Ihrem Produkt um kein Medizinprodukt, oder der klinische Nutzen des Produkts ist nicht klar erkennbar.' }, // exit
            { answerText: 'leichte gesundheitliche Schäden', isCorrect: true, questionArray: 'no additional questions' },
            { answerText: 'schwere gesundheitliche Schäden oder Behinderung', isCorrect: true, questionArray: 'no additional questions' },
            { answerText: 'Tod', isCorrect: true, questionArray: 'no additional questions' },
        ],
    },
];



