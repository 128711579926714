import {questions, questionsReliefOfDiseases} from "../Questions/Questions";
import React, {useContext, useEffect, useState} from "react";
import './AnswerSection.scss';
import {navigate} from "gatsby";
import QuizContext from "../../context/context";
import RadioButtons from "./RadioButtons";

type Props = {
    handleAnswerOptionClick_ReliefOfDiseases(isCorrect: boolean, question: string, answer: string, additionalQuestions?: string, exitReason?: string): void;
    //handleBack(question: string, answer: string): void;
}

export default function ReliefOfDiseasesQuestions(props: Props) {

    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const [questionsArray, setQuestionsArray] = useState<string>("");
    const [answer, setAnswer] = useState<string>("");
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [currentQuestionNumberReliefOfDiseases, setCurrentQuestionNumberReliefOfDiseases] = useState(0);
    const [goBack, setGoBack] = useState(false);
    const {additionalQuizQuestions, setAdditionalQuizQuestions} = useContext(QuizContext);

    const handleDisableButton = (disableButton) => {
        setDisableNextButton(disableButton);
    }

    const cancelQuiz= () => {
        navigate('/');
    }

    let currentQuestion = questionsReliefOfDiseases[currentQuestionNumberReliefOfDiseases].questionText;

    // Antwortoptionen zur aktuellen Frage
    let answersToCurrentQuestion = questionsReliefOfDiseases[currentQuestionNumberReliefOfDiseases].answerOptions;
    // ExitReasons in den Antwortoptionen zur aktuellen Frage (Array)
    let exitReasonCurrentQuestion = answersToCurrentQuestion.map(a => a.exitReason);
    let exitReason = '';
    exitReasonCurrentQuestion.forEach((exit) => {if(exit) {exitReason = exit}});

    const goBackToPreviousQuestion = () => {
        setGoBack(true);
    }

    useEffect(() => {
        if (goBack) {
            setAdditionalQuizQuestions("diagnosisOrTherapyOfDiseases");

        }
    }, [goBack])

    return (
        <div>
            <>
            <RadioButtons 
                question={questionsReliefOfDiseases[currentQuestionNumberReliefOfDiseases]} 
                answer={answer} 
                setAnswer={setAnswer} 
                setIsCorrect={setIsCorrect} 
                setQuestionsArray={setQuestionsArray} 
                handleDisableButton={handleDisableButton} 
            />

                <div className="QuestionProceedRelief">
                    <button className="QuizButtonCancel" onClick={() => cancelQuiz()}>Abbrechen</button>
                    <button className="QuizButtonBack" onClick={() => {
                        goBackToPreviousQuestion();
                    }}>
                        Zurück
                    </button>
                    <button className="QuizButtonNextQuestion"
                            disabled={disableNextButton}
                            onClick={() => {
                                props.handleAnswerOptionClick_ReliefOfDiseases(isCorrect, currentQuestion, answer, questionsArray, exitReason);
                            }}>
                        Nächste Frage
                    </button>
                </div>
            </>
        </div>
    );
}

