import {questionsHealthDamage, questionsReliefOfDiseases} from "../Questions/Questions";
import React, {useContext, useEffect, useState} from "react";
import './AnswerSection.scss';
import {navigate} from "gatsby";
import QuizContext from "../../context/context";
import RadioButtons from "./RadioButtons";

type Props = {
    handleAnswerOptionClick_HealthDamage(isCorrect: boolean, question: string, answer: string, additionalQuestions?: string, exitReason?: string): void;
    //handleBack(question: string, answer: string): void;
}

export default function HealthDamageQuestions(props: Props) {

    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const [questionsArray, setQuestionsArray] = useState<string>("");
    const [answer, setAnswer] = useState<string>("");
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [currentQuestionNumberHealthDamage, setCurrentQuestionNumberHealthDamage] = useState(0);
    const [goBack, setGoBack] = useState(false);
    const {additionalQuizQuestions, setAdditionalQuizQuestions} = useContext(QuizContext);
    const { currentQuestionNumber, setCurrentQuestionNumber } = useContext(QuizContext);

    const handleDisableButton = (disableButton) => {
        setDisableNextButton(disableButton);
    }

    const cancelQuiz= () => {
        navigate('/');
    }

    let currentQuestion = questionsHealthDamage[currentQuestionNumberHealthDamage].questionText;

    // Antwortoptionen zur aktuellen Frage
    let answersToCurrentQuestion = questionsHealthDamage[currentQuestionNumberHealthDamage].answerOptions;
    // ExitReasons in den Antwortoptionen zur aktuellen Frage (Array)
    let exitReasonCurrentQuestion = answersToCurrentQuestion.map(a => a.exitReason);
    let exitReason = "";

    exitReasonCurrentQuestion.forEach((exit) => {if(exit) {exitReason = exit}});

    const goBackToPreviousQuestion = () => {
        setGoBack(true);
        setCurrentQuestionNumber(currentQuestionNumber - 1)
    }

    useEffect(() => {
        if (goBack) {
            if (currentQuestionNumber > 0) {
                // set to "no additional questions" to render the standard question array again
                setAdditionalQuizQuestions("no additional questions");
                console.log("setting to no addiontal questions");
            }
        }
    }, [goBack])

    return (
        <div>
            <>
            <RadioButtons 
                question={questionsHealthDamage[currentQuestionNumberHealthDamage]} 
                answer={answer} 
                setAnswer={setAnswer} 
                setIsCorrect={setIsCorrect} 
                setQuestionsArray={setQuestionsArray} 
                handleDisableButton={handleDisableButton} 
            />

                <div className="QuestionProceed">
                    <button className="QuizButtonCancel" onClick={() => cancelQuiz()}>Abbrechen</button>
                    <button className="QuizButtonBack" onClick={() => {
                        goBackToPreviousQuestion();
                    }}>
                        Zurück
                    </button>
                    <button className="QuizButtonNextQuestion"
                            disabled={disableNextButton}
                            onClick={() => {
                                props.handleAnswerOptionClick_HealthDamage(isCorrect, currentQuestion, answer, questionsArray, exitReason);
                            }}>
                        Nächste Frage
                    </button>
                </div>
            </>
        </div>
    );
}

