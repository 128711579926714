import React from "react";
import "./RadioButtons.scss"
export default function RadioButtons({question, answer, setIsCorrect, setAnswer, setQuestionsArray, handleDisableButton}){
    return(
        <div className="answersContainer">
        {question.answerOptions.map((answerOption, i) => (
            <div className="RadioButton" key={i}>
                <input className="RadioButtonButton" id={i} type="radio" value={answerOption.answerText}
                       checked={answerOption.answerText == answer}
                       onChange={() => {
                           setIsCorrect(answerOption.isCorrect)
                           setAnswer(answerOption.answerText)
                           setQuestionsArray(answerOption.questionArray);
                           handleDisableButton(false);
                       }}/>
                <label className="RadioButtonLabel" htmlFor={i}>
                    {answerOption.answerText}    
                    {answerOption.notice && <div className="RadioButtonLabelNotice">{answerOption.notice}</div>}
                </label>
            </div>
        ))}
    </div>
    );
}