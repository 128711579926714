import React, {useEffect, useState, useContext} from 'react';
import {validate} from 'react-email-validator';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
import QuizContext from "../../context/context";
import './Textfield.scss';
import '../../ContactForm/ContactForm.scss'
import { navigate } from 'gatsby-link';
type Props = {
    //TODO fix 'any'
    answersQuestionnaire: any[];
}

init("user_1SjEpUCgt3RESRO624VTE");

export default function Textfield(props: Props) {

    const [valueName, setValueName] = useState('');
    const [validName, setValidName] = useState(false);
    const [emptyName, setEmptyName] = useState(true);

    const [valueMail, setValueMail] = useState('');
    const [validMail, setValidMail] = useState(false);
    const [emptyMail, setEmptyMail] = useState(true);

    const [submittingPossible, setSubmittingPossible] = useState(false);
    const { quizSuccessful, setQuizSuccessful } = useContext(QuizContext);
    const [submitButtonText, setSubmitButtonText] = useState('Antworten abschicken');

    useEffect(() => {
        let isNameEmpty = valueName.length <= 0;
        setEmptyName(isNameEmpty);
        let isNameValid = validateName(valueName);
        setValidName(isNameValid);

        let isMailEmpty = valueMail.length <= 0;
        setEmptyMail(isMailEmpty);
        let isMailValid = validate(valueMail);
        setValidMail(isMailValid);

        if (isNameValid && isMailValid) {
            setSubmittingPossible(true);
        }
        else {
            setSubmittingPossible(false);
        }
        console.log("submitting possible? ", submittingPossible);
    }, [valueName, emptyName, valueMail, emptyMail])

    const validateName = (valueName: string) => {
        // ---> see https://regexr.com/
        // at least 2 words, each beginning with 1 capital letter, no numbers, no special characters
        const regex = new RegExp('^[A-Z][a-z]+(?:\\s[A-Z][a-z]+)+$');
        return regex.test(valueName);
    }

    const prettyQuestions = (questions) => {
        let output = "";
        questions.forEach(question => {
            //include breaks when sending HTML
            output += question.question;// + "<br>";
            output += question.answer;// + "<br><br>";
        });
        return output;
    }

    const handleSubmitWholeQuestionnaire = (evt) => {
        evt.preventDefault();

        //additional must be seperate, because the questions get send as plain HTML
        //the addional is a freetext - it is a security hazard to send userinput as plain HTML
        let additional = "Keine Anmerkung"
        if(props.answersQuestionnaire.at(-1).question == "Haben Sie noch weitere, wichtige Anmerkungen?"){
            additional = props.answersQuestionnaire.pop().answer;
        } 
        let mailText = prettyQuestions(props.answersQuestionnaire);
        console.log("mailtext: ", mailText);

        // Send Questionnaire via EmailJs
        const templateParams = {
            questionnaire: mailText,
            additional: additional,
            fromSenderName: valueName,
            fromSenderEmail: valueMail
        };

        setSubmittingPossible(false);
        setSubmitButtonText("wird gesendet ... ");

        emailjs.send('service_tu3djg9', 'template_fxexs89', templateParams)
            .then(function (response) {
                console.log('Email successfully sent!', response.status, response.text);
                navigate("/QuizSuccess");
          }, function (err) {
                console.log('Failed to send email.', err);
                setSubmittingPossible(true);
        });        
    }

    return (
        <div className="TextfieldContainer">
            <div className="TextfieldHeading">Vielen Dank, dass Sie an unserem Quiz teilgenommen haben!</div>
            <div className="TextfieldText">
                Unsere Expert*innen analysieren Ihre Ergebnisse schnellstmöglich und geben Ihnen individuelles
                Feedback zu Ihrem Produkt. Zu diesem Zweck benötigen wir Ihren Namen sowie Ihre E-Mail-Adresse, um Ihnen die
                Analyse zuzusenden. Diese erhalten Sie innerhalb der nächsten 24 Stunden.
            </div>
            <form className="TextfieldForm" onSubmit={handleSubmitWholeQuestionnaire}>
                <input id="QuizNameInput" className="NameInput" type="text" placeholder="Vorname, Nachname" value={valueName} onChange={e => setValueName(e.target.value)}/>             
                <div id="InvalidName" className="InvalidInput">{!validName && !emptyName && "Dies scheint kein gültiger Name zu sein."}</div>

                <input id="QuizMailInput" className="MailInput" type="text" value={valueMail} placeholder="E-Mail" onChange={e => setValueMail(e.target.value)}/>            
                <div id="InvalidMail" className="InvalidInput">{!validMail && !emptyMail && "Dies scheint keine gültige E-Mail Adresse zu sein."}</div>   

                <input className="SendButton" type="submit" value={submitButtonText} disabled={!submittingPossible}/>
            </form>
        </div>
    );
}
