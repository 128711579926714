import React, { useEffect, useState } from 'react';
import { navigate } from "gatsby";
import './Exit.scss';
import ContactForm from '../../ContactForm/ContactForm';

type Props = {
    text: string;
}

export default function Exit(props: Props) {

    const [showContact, setShowContact] = useState(false);
    const [contactSuccess, setContactSucess] = useState(false);

    useEffect(() => {
        if (contactSuccess){exitPage();}
    }, [showContact, contactSuccess]);

    const exitPage= () => {
        navigate('/');
    }
    
    return (
        <div className="ExitContainer">
            {showContact ? 
                <ContactForm from="QuizPage" showContact={setShowContact} success={setContactSucess}/>
            :
                <>
                <div className="ExitText">{props.text}</div>
                <div className="ButtonContainer">
                    <button className="BackButton" onClick={() => exitPage()}>Zurück zur Hauptseite</button>
                    <button className="ContactButton" onClick={() => setShowContact(true)}>Kontaktieren Sie uns</button> 
                </div>
                </>
            }
        </div>
    );
}
